.facility-form {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 500px; /* or your preferred max-width */
}

.facility-form h3 {
  text-align: center;
  margin-bottom: 20px;
}

.facility-form form {
  display: flex;
  flex-direction: column;
}

.facility-form label {
  color: #2f2c9b; /* Purple color */
  font-size: 18px; /* Increase the font size */
  font-weight: bold; /* Make the font bolder */
  margin-bottom: 10px;
}

.facility-form input[type="text"],
.facility-form textarea,
.facility-form select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.facility-form textarea {
  resize: vertical; /* Allows the user to resize the textarea vertically */
  box-shadow: none;
}

.facility-form button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  background-color: #4540db; /* Example primary color */
}

.facility-form button[type="button"] {
  background-color: #808080; /* Example secondary color */
}

.facility-form button:hover {
  opacity: 0.8;
}

.facility-form input[type="text"]:focus,
.facility-form textarea:focus,
.facility-form select:focus {
  outline: none;
  border-color: #5c6bc0; /* Adjust the color to match your theme */
}
