/* Title for the facilities page */
.facilities-title {
  text-align: center;
  margin-top: 20px;
}

/* Container for the facilities page */
.facilities-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f4f8;
  min-height: 100vh;
  max-width: 500px; /* Adjust this value as needed */
  width: 10 0%; /* Adjust width as necessary */
  margin: 0 auto; /* Center the container */
}

/* Style for the facility card */
.facility-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px; /* Added margin-top for spacing */
  width: 100%; /* Adjust width as necessary */
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: 40px; /* Increase the bottom margin */
}

.facility-card:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.facility-card h5 {
  color: #2f2c9b;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.facility-card p {
  color: #333;
  margin-bottom: 1rem;
}

/* Buttons for add, edit, and delete actions */
.facility-action-button {
  background-color: #4540db;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
}

.facility-action-button:hover {
  background-color: #7975d6;
}

/* Style for the edit and delete buttons */
.edit-button {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: #4540db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 36px;
  width: 125px;
  margin-top: 20px;
  margin-right: 10px;
  float: right;
}

.edit-button:hover {
  background-color: #7975d6;
  color: #ffffff;
}

/* Separate styling for delete button to make it stand out as a destructive action */
.delete-button {
  background-color: #d9534f;
}

.delete-button:hover {
  background-color: #c9302c;
}

/* Style for the form when in edit mode */
.edit-facility-form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%; /* Match width with facility card */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.edit-facility-form input[type="text"],
.edit-facility-form input[type="time"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-facility-form button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

/* Style for the group of buttons */
.button-group {
  display: flex;
  justify-content: center;
  gap: 150px; /* Adjust the gap between buttons as needed */
}

.no-facilities-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px; /* Added margin-top for spacing */
  text-align: center; /* Centers text */
  width: 100%; /* Adjust width as necessary */
}

.no-facilities-text {
  color: #2f2c9b !important; /* Adjust the shade of purple as needed */
  font-weight: bold; /* Makes the font bolder */
}
.pagination-container {
  /* existing styles */
  padding-top: 40px; /* Add top padding */
}
