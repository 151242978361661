
  .popup_container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the popup appears above other elements */

  }
  
  .popup_content {
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    max-width: 400px; 
    position: relative;
  }
  
  .close_popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    color: #888888; /* Grey color for the close button */
  }

  .h4_db{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#4540db;
    text-align: center;
   
  }


  
  .btn-reg{
font-family: "Roboto", sans-serif; 
  background-color: #4540db; 
  color: #fff; 
  padding: 10px 20px; 
  border: none; 
  border-radius: 4px; 
  font-size: 18px; 
  cursor: pointer; 
  transition: all 0.3s ease; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  margin-top: 25px;
  display: inline-block; 
  width: 100%; 

  }
  
  .btn-reg:hover {
    background-color: #7975d6; 
    color: #ffffff; 
  
  }
  
  .key_label{
    color:#4540db;
    justify-content: left !important;
  }