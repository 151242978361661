.Requestcontainer {
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.form-box {
    font-family: "Roboto", sans-serif;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.titleform {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #2f2c9b;
    display: inline-block;
}

.Requesttitle {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: #4540db;
    display: inline-block;
    font-size: 16px;
}

.dropdown {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #2f2c9b;
    justify-content: left;
    text-align: left;
    display: flex;
}

.cancel-button {
    font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
    font-weight: 400;
    font-size: 20px; /* Chosen font size */
    background-color: #888; /* Chosen background color from signup2 branch */
    color: #fff; /* Assuming var(--white) is white, replaced with #fff */
    border: none; /* No border */
    border-radius: 4px; /* Chosen border-radius */
    cursor: pointer; /* Hand cursor on hover */
    transition: all 0.3s ease; /* Example transition */
    display: flex; /* Display style */
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Added box-shadow for consistency */
    height: 36px;
    width: 150px;
    margin-top: 10px;
    margin-right: 30px;
    float: left;
    text-decoration: none;
}

.cancel-button:hover {
    background-color: #a0a0a0; /* Change background color on hover to the lighter lilac color */
    color: #ffffff; /* Change text color on hover to white */
}

.submit-button {
    font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
    font-weight: 400;
    font-size: 20px; /* Chosen font size */
    background-color: #4540db; /* Chosen background color from signup2 branch */
    color: #fff; /* Assuming var(--white) is white, replaced with #fff */
    border: none; /* No border */
    border-radius: 4px; /* Chosen border-radius */
    cursor: pointer; /* Hand cursor on hover */
    transition: all 0.3s ease; /* Example transition */
    display: flex; /* Display style */
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Added box-shadow for consistency */
    height: 36px;
    width: 150px;
    margin-top: 10px;
    float: right;
}

.submit-button:hover {
    background-color: #7975d6; /* Change background color on hover to the lighter lilac color */
    color: #ffffff; /* Change text color on hover to white */
}

textarea {
    font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
    font-size: 16px; /* Chosen font size */
    padding: 12px; /* Chosen padding */
    border: 1px solid #e0e0e0; /* Define border */
    border-radius: 4px; /* Define border radius */
    width: 100%; /* Set width to 100% */
    height: 375px;
    resize: vertical; /* Allow vertical resizing */
    margin-top: 10px; /* Adjust margin */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Added box-shadow for consistency */
    resize: none !important;
}

textarea:focus {
    border-color: #4540db; /* Chosen border color on focus */
    outline: none; /* Remove default focus outline */
}


select {
    margin-bottom: 20px;
}

::placeholder {
    color:#6160b8;
}