*,
::after,
::before {
  box-sizing: border-box;
}

.App {
  background-color: white;
}

:root {
  --primary-700: #705eff;
    --primary-bg: #242526;
    --secondary-bg: #fff;
    --primary-text-color: #555;
    --secondary-text-color: #cecece;
    --border-radius: 8px;
    --speed: 500ms;

    /* grey */
    --grey-50: #f0f4f8;
    --grey-100: #d9e2ec;
    --grey-200: #bcccdc;
    --grey-300: #9fb3c8;
    --grey-400: #829ab1;
    --grey-500: #627d98;
    --grey-600: #486581;
    --grey-700: #334e68;
    --grey-800: #243b53;
    --grey-900: #102a43;
    /* rest of the colors */
    --black: #222;
    --white: #fff;
    --red-light: #f8d7da;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green-dark: #0f5132;
    /* fonts  */
    --headingFont: "Roboto Condensed", Sans-Serif;
    --bodyFont: "Roboto", Sans-Serif;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7em;
    /* rest of the vars */
    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-900);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 500px;
    --fluid-width: 90vw;
    --breakpoint-lg: 992px;
    --nav-height: 6rem;
    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
  
  .header {
    width: 100%;
    height: 10%;
  }
  
  .header-item-holder {
    display: inline-block;
  }
  
  .logo {
    cursor: pointer;
  }
  
  .login-ref {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: black;
    text-decoration: none;
  }
  
  .header-items {
    display: inline;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  
  .signup-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
  }
  
  .form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .form-subtitle {
    text-align: center;
    font-size: smaller;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }
  
  .form-signin {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #4540db;
    font-weight: bold;
    text-decoration: none;
  }
  
  .form-group {
    width: auto;
    position: relative;
  }
  
  
  label {
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }
  
  .btndiv {
    padding-top: 10px;
  }
  
  
  /*=======================================General CSS resource========================== */

/* fonts */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
html {
  font-size: 100%;
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1.5rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
  text-transform: capitalize;
  letter-spacing: var(--letterSpacing);
}

h1 {
  margin-top: 0;
  font-weight: bold;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-weight: bold;
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text-small {
  font-size: var(--small-text);
}

a {
  text-decoration: none;
  letter-spacing: var(--letterSpacing);
}

a,
button {
  line-height: 1.15;
}

button:disabled {
  cursor: not-allowed;
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* buttons */

.btn {
  background-color: #4540db;
  width: 1;
  cursor: pointer;
  color: var(--white);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.btn:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

.btn-2 {
  background-color: #4540db;
  width: 1;
  cursor: pointer;
  color: var(--white);
  border: transparent;
  border-radius: var(--borderRadius);
  letter-spacing: var(--letterSpacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-2);
  transition: var(--transition);
  text-transform: capitalize;
  display: inline-block;
}

.btn-2:hover {
  background: var(--primary-700);
  box-shadow: var(--shadow-3);
}

.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}

.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}

.btn-block {
  width: 100%;
}

.btn-hero {
  font-size: 1.25rem;
  padding: 0.5rem 1.25rem;
}

.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
}

.btn-danger:hover {
  background: var(--red-dark);
  color: var(--white);
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 150px;
}

.dropdown select {
  padding: 0.375rem 0.75rem;
  font-size: small;
  font-weight: 500;
  line-height: 1.5;
  background-color: var(--primary-500);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: white;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  height: 41px;
  width: 85px;
  text-align: center;
}

.dropdown select:focus {
  border-color: var(--primary-700);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.dropdown-select option {
  color: white;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.applicant-style {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.confirm-btn {
  color: #4540db;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 12px 16px;
  text-transform: uppercase;
  border: none;
  background-color: var(--backgroundColor);

  border-radius: var(--borderRadius);
}

.confirm-btn:hover {
  background: rgb(1, 104, 50);
  box-shadow: var(--shadow-3);
  color: white;
}

/* alerts */

.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}

.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}

/* form */

.form {
  width: 100vw;
  max-width: var(--fixed-width);
  max-height: 95%;
  background: var(--white);
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow-2);
  padding: 2rem 2.5rem;
  margin: 2rem auto;
  transition: var(--transition);
}

.form:hover {
  box-shadow: var(--shadow-4);
}

.checkbox {
  display: flex;
}

.check {
  margin-left: 9px;
  margin-bottom: 10px;
}

.form-label {
  display: block;
  font-size: var(--smallText);
  /* margin-bottom: 0.5rem; */
  letter-spacing: var(--letterSpacing);
}

.form-input,
.form-textarea,
.form-select {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--borderRadius);
  background: rgba(16, 94, 33, 0.102);
  border: 1px solid #CED3C2;
}

.form-input,
.form-select,
.btn-block {
  height: 35px;
}

.form-row {
  margin-bottom: 1rem;
}

.form-row-side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Adjust alignment as needed */
  }
  
.form-textarea {
  height: 7rem;
}

::placeholder {
  font-family: inherit;
  color: var(--grey-400);
}

.form-alert {
  color: var(--red-dark);
  letter-spacing: var(--letterSpacing);
  text-transform: capitalize;
}

/* alert */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  border: 5px solid var(--grey-400);
  border-radius: 50%;
  border-top-color: var(--primary-500);
  animation: spinner 2s linear infinite;
}

.loading-center {
  margin: 0 auto;
}

/* title */

.title {
  text-align: center;
}

.title-underline {
  background: var(--primary-500);
  width: 7rem;
  height: 0.25rem;
  margin: 0 auto;
  margin-top: -1rem;
}

.container {
  margin: 0 2;
}

.full-page {
  min-height: 100vh;
}


