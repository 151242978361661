
.pagination {
  margin: 10px 0 0 0;
  text-align: center;
}

.pagination button {
  font-size: 17px;
  border: none;
  background-color: var(--backgroundColor);
  color: #4540db;
  font-weight: 500;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.pagination button:hover {
  background-color: #4540db;
  color: #fff;
}

#pageButton.active {
  color: white;
  background-color: #4540db;
}

#pageButton {
  margin: auto 10px;
}

#pageButton.active:hover {
  background-color: #4540db;
  color: #fff;
}

.next {
  color: #4540db;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 12px 16px;
  text-transform: uppercase;
  border: none;
  background-color: var(--backgroundColor);
}

.prev {
  color: #4540db;
  font-weight: bold;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 12px 16px;
  text-transform: uppercase;
  border: none;
  background-color: var(--backgroundColor);
}