.DB_title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4540db;
  display: inline-block;
  margin-top: 2.5%;
}

.title_container {
  text-align: center;
  margin-bottom: 5%;
  position: relative;
}

.condo_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the cross axis (top) */
}

/* Default styles */
.details-button-condo-files {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  position: absolute;
  top: 85px;
  left: 35%;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 200px;
  background-color: #4540db;
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  align-self: flex-end;
  margin-bottom: 10px;
}

/* Media query for responsiveness */
@media screen and (max-width: 1200px) {
  .details-button-condo-files {
    font-size: 19px;
    height: 34px;
    width: 190px;
    top: 75px;
    left: 41%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 992px) {
  .details-button-condo-files {
    font-size: 17px;
    height: 30px;
    width: 170px;
    top: 70px;
    left: 39%;
  }
}
/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .details-button-condo-files {
    font-size: 18px;
    height: 32px;
    width: 180px;
    top: 65px;
    left: 35%;
    transform: translateX(-50%);
  }
}

/* Additional media query for even smaller screens */
@media screen and (max-width: 576px) {
  .details-button-condo-files {
    font-size: 16px;
    height: 28px;
    width: 160px;
    top: 45px;
    left: 20%;
  }
}

.details-button-condo-files:hover {
  background-color: #7975d6;
  color: #ffffff;
}

.content_container {
  display: flex;
  justify-content: center;
}

.buttons_container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.top-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

@media screen and (max-width: 650px) {
  .top-button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .top-button-container > div {
    margin-bottom: 15px;
  }
}

.white_card {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  margin-bottom: 10%;
}

.card_title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4540db;
  font-weight: bolder;
}

.buttonDetails {
  background-color: #4540db;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 5%;
}

.buttonDetails:hover {
  background-color: #7975d6;
}

.center-page {
  position: relative;
}
.center-page.modal-open {
  overflow: hidden; /* Hide the page content when the modal is open */
}

.property-buttons,
.property-link {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: #4540db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 36px;
  width: 150px;
  flex: 1;
  margin-right: 25px;
  margin-left: 25px;
}

.property-link {
  display: inline-block;
  line-height: 36px;
  width: 200px;
  font-size: 20px;
}

.property-buttons:hover,
.property-link:hover {
  background-color: #7975d6;
  color: #ffffff;
}

.centered-text-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none; /* Removes underline from links */
}
