h2,
h3 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  position: relative;
  margin-bottom: 3px;
  color: #2f2c9b;
}

.add-property-container {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding-top: 50px;
}

.add-property-form {
  font-family: "Roboto", sans-serif;
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.image-preview {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Input styles */
.condo-form input,
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-label {
  display: flex;
  margin-bottom: 6px;
  color: #382fb8;
  font-weight: bold;
}


input
select
 {
  font-family: "Roboto", sans-serif;
  width: relative;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 16px;
  
}
.custom-select {
  width: 10%;
 height: auto; 
  display: flex;
}

.add-condo-button,
.add-property-button {
  background-color: #382fb8;
  color: #ffffff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  flex: 2;
  margin-right: 10px /* Use flex property to distribute available space equally */
}
.submit-condo-button{
    background-color: #382fb8;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}

.add-condo-button:hover,
.add-property-button:hover {
  background-color: #2f2c9b;
}
.delete-condo-button{
  background-color: red;
  color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}


.condo-form {
    background-color: #ffffff; /* Match the background color of the form */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
  }
  
  .condo-form select {
    margin-bottom: 20px;
    color: #2f2c9b; /* Match the color of other text in the form */
  }
  
  .condo-form input {
    margin-bottom: 20px;
    text-align: left;
    color: #2f2c9b; /* Match the color of other text in the form */
  }
  
  .condo-form input,
  .condo-form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .condo-form label {
    display: flex;
    margin-bottom: 6px;
    color: #382fb8; /* Match the color of other headers in the form */
    font-weight: bold;
  }
  
  .condo-form img {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
.condo-preview {
    background-color: #ffffff; /* Match the background color of the form */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
   
  }
  
  .condo-preview h5 {
    font-size: 1.2em;
    color: #382fb8; /* Match the color of other headers in the form */
    font-family: "Roboto", sans-serif;
    font-weight: bold;

  }
  
  .condo-preview p {
    margin-bottom: 5px;
    color: #2f2c9b; /* Match the color of other text in the form */
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    display: flex; 
  }
  
  .condo-preview img {
    max-width: 50%;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
  }
  .custom-select {
    width: 10%;
   height: auto; 
    display: flex;
  }
  .button-container {
    display: flex;
    justify-content: space-between; 
    
  
  }
  