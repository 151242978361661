

h2{
  font-family: Roboto, Arial, Helvetica, sans-serif;
  position: relative;
  margin-bottom: 3px;
  color:#2f2c9b;
}

.signup-container {
  font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  min-height: 100vh; /* Ensure the container takes up at least the viewport height */
  background-color: #f4f4f4;
  padding-top: 50px; /* Add padding to the top */
}

.signup-form {
  font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

/* Input styles */
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.signup {
  display: block;
  margin-bottom: 6px;
  color:#382fb8;
  font-weight: bold;
}

input,
select {
  font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
  width: 100%;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 16px;
}


/* Forgot password link */
.forgot-password-link {
  font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
  display: block;
  margin-top: 10px;
  text-align: right;
  text-decoration: none;
  color:#3531a1;
  font-weight: bold;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.footerwrapper{
  background-color:#f0f4f8 !important;
}