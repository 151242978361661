  .facility-type{
    font-family: "Roboto", sans-serif;
    position: relative;
    color:#2f2c9b;
    font-size: 15px;
    font-weight: 500;
    margin-top: 12px; 
  }

  .facility-title{
    font-family: "Roboto", sans-serif;
    position: relative;
    color:#2f2c9b;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px; 
  }

  .facility-description{
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color:grey;
  }

  .component-container{
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 14px;
    height: auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
  }

  .facility-component-container{
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 14px;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 400px;
  }


  .make-reservation-button{
    background-color: #382fb8;
    color: #ffffff;
    margin-top: 5%;
    padding: 5px;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    width: 40%;
    height: fit-content;
    text-align: center;
  }
  .make-reservation-button:hover{
    background-color: #2f2c9b;
  }

  .close-button{
    position: absolute;
    top: 8px;
    margin-bottom: 20px;
    right: 8px;
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(0, 0, 0, 0); /* Adding a border for visibility */
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: red;
    outline: none;
    user-select: none;
  }
  .close-button:hover {
    color: #aaa; /* Slightly paler color on hover */
  }
  