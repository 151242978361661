
h2,
h3 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  position: relative;
  margin-bottom: 3px;
  color: #2f2c9b;
}
.add-condo-container {
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding-top: 50px;
  }
  .add-condo-form {
    font-family: "Roboto", sans-serif;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  .condo-image-preview {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 1px solid #ccc;
    overflow: hidden;
  }
  .condo-image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .condo-form input,
.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-label {
  display: flex;
  margin-bottom: 6px;
  color: #382fb8;
  font-weight: bold;
}
.custom-checkbox {
  width: 25px; /* Adjust size as needed */
  height: 25px; /* Adjust size as needed */
  margin-left: 20px; /* Adjust spacing as needed */
  border: 2px solid #000; /* Black border */
  border-radius: 5px; /* Sharp edges */
}

input,
select
 {
  font-family: "Roboto", sans-serif;
  width: relative;
  padding: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 16px;
  
}
.custom-select {
    width: 10%;
   height: auto; 
    display: flex;
  }
  .add-condo-button{
    background-color: #382fb8;
    color: #ffffff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
}
.add-condo-button:hover,
.submit-condo-button:hover,
.add-property-button:hover{
  background-color: #7975d6; /* Change background color on hover to the lighter lilac color */
  color: #ffffff; /* Change text color on hover to white */
}