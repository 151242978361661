.notif{
    height: auto;
    width: 50px;
    color:  #4540db;
    transition: color 0.3s; /* Smooth background color transition on hover */
}

.notif:hover {
    color:#7975d6;
}

.notif-wrapper{
    margin-right: 150px;
    position: relative; 
}

.notifPopup {
  width: 20px;
  height: 20px;
  background-color: rgb(238, 90, 90);
  border-radius: 50%;
  position: absolute;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-left:50%;
}

.notif:hover{
   color:#7975d6;
}

.notification-header {
    width: 100%;
    text-align: center !important;
    padding: 20px 0;
    line-height: 1.2rem;
  }

.notif-title{
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-text-color);
}

.myDropdownNotif {
    position: absolute;
    top: 115px;
    right: 160px;
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: auto;
    min-width: 210px;
    z-index: 999;
    min-height:130px
  }
  
  .myDropdownNotif::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    background: var(--secondary-bg);
    transform: rotate(45deg) !important;
  }
  
  .myDropdownNotif.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) !important;
    transition: var(--speed) ease !important;
    position: absolute;
    z-index: 9999;
  }
  
  .myDropdownNotif.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px) !important;
    transition: var(--speed) ease !important;
  }
  
  .no-notifications-msg{
    color:#4540db;
    text-align: center !important;
  }


  .See-all {
    font-family: "Roboto", sans-serif; 
    background-color: #4540db; 
    color: #fff; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 4px; 
    font-size: 14px; 
    cursor: pointer; 
    transition: all 0.3s ease; 
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  }
  
  .See-all:hover {
    background-color: #7975d6; /* Change background color on hover to the lighter lilac color */
    color: #ffffff; /* Change text color on hover to white */
  }

  .btn-div-notif{
    padding-top:15%;
    display: flex;
    justify-content: center;
  }

  .notification-list{
    padding-right: 3%;
  }
  .notification-list::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.notification-list::-webkit-scrollbar-track {
  background: #cdcce9;  /* Color of the scrollbar track */
}

.notification-list::-webkit-scrollbar-thumb {
  background: #7975d6;
  border-radius: 5px; 
  transition: 0.3s;
}

.notification-list::-webkit-scrollbar-thumb:hover {
  background: #4540db; 
  transition: 0.3s;
  
}
