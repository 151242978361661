.DB_title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4540db;
  display: inline-block;
  margin-top: 2.5%;
}

.title_container {
  text-align: center;
  margin-bottom: 5%;
}

.content_container {
  display: flex;
  justify-content: center;
}

.white_card {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  margin-bottom: 10%;
}

.card_title {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #4540db;
  font-weight: bolder;
}
.button {
  background-color: #4540db;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 5%;
}

.button:hover {
  background-color: #7975d6;
}
.center-page {
  position: relative;
}

.property-buttonsUF,
.property-linkUF {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: #4540db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 36px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 16px 0; /* Adjust top and bottom padding */
}

.property-linkUF {
  width: 100%;
}

.property-buttonUFs:hover {
  background-color: #7975d6;
  color: #ffffff;
}
