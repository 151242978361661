.Financial-info {
    background-color: #ffffff;
    font-family: "Roboto", sans-serif; /* Add Roboto as the font family */
    display: block;
}

.FinanceText {
    font-family: "Roboto", sans-serif;
    position: relative;
    margin-bottom: 0px;
    color:#2f2c9b;
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
}

.other-info {
    text-align: left;
    margin-left: 10px;
}

.other-info1 {
    text-align: left;
}

.other-info2 {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.textDetail {
    display: inline-block;
    vertical-align: middle;
}

.green-check {
    color: green;
    margin-left: 20px;
    display: inline-block;
    position:relative;
    margin-bottom: 5px;
    justify-self: left;
}

.red-cross {
    color: red;
    margin-left: 20px;
    display: inline-block;
    position:relative;
    margin-bottom: 5px;
    justify-self: left;
}

.PayingRent {
    font-family: "Roboto", sans-serif; 
    font-weight: 400;
    font-size: 20px; 
    background-color: #4540db; 
    color: #fff; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: all 0.3s ease; 
    display: flex; 
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
    height: 36px;
    width: 125px;
    margin-top: 20px;
    margin-right: 10px;
    float: right;
}

.PayingRent:hover {
    background-color: #7975d6;
    color: #ffffff;
  }