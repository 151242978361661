
.editProfile,
.changePassword,
.saveChanges,
.cancelButton {
  background-color: #4540db !important;
  color: white !important;
  width: 250px !important;
  transition: 0.3s ease-in-out all;
}

.editProfile:hover,
.changePassword:hover,
.saveChanges:hover,
.cancelButton:hover {
  background-color: #382fb8 !important;
}

.cancelButton {
  width: 100% !important;
}

.footerwrapper{
  background-color: rgb(248,249,250) !important;
}
.btn-danger {
  background: var(--red-light);
  color: var(--red-dark);
  margin-left: 0 !important; /* Added this line to override Bootstrap styles */
}